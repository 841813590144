import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_additional_properties = _resolveComponent("additional-properties")!

  return (_ctx.loaded)
    ? (_openBlock(), _createBlock(_component_additional_properties, {
        key: 0,
        estimationIdentifier: _ctx.estimationIdentifier,
        type: 'packaging',
        propertiesFromConfiguration: _ctx.packagingFromConfiguration,
        estimationProperties: _ctx.estimationPackaging,
        onModelUpdated: _ctx.updateAdditionalProperties
      }, null, 8, ["estimationIdentifier", "propertiesFromConfiguration", "estimationProperties", "onModelUpdated"]))
    : _createCommentVNode("", true)
}