
import { Options, Vue } from 'vue-class-component';
import { FootwearEstimationClient } from '@/services/Services';
import AdditionalProperties from './components/additionalProperties.vue';
import * as OM from '@/Model';

@Options({
    components: {
        AdditionalProperties
    }
})
export default class Packaging extends Vue {

    estimationIdentifier: string = "";
    packagingFromConfiguration: OM.FootwearAdditionalPropertiesConfiguration[] = [];
    estimationPackaging: OM.FootwearAdditionalProperty[] = [];

    loaded: boolean = false;
    
    created() {
        this.estimationIdentifier = this.$route.params.estimationIdentifier.toString();
        this.init();
    }

    init() {
        Promise.all([
            FootwearEstimationClient.getPackagingFromConfiguration(),
            FootwearEstimationClient.getPackagingAdditionalsFromEstimation(this.estimationIdentifier)
        ])
        .then( xs => {
            this.packagingFromConfiguration = xs[0];
            this.estimationPackaging = xs[1];

            setTimeout(() => {
                this.loaded = true;
            }, 300);
        })
    }

    updateAdditionalProperties(model: OM.FootwearEstimationAdditionalPropertyVM) {
        FootwearEstimationClient.updateAdditionalProperties(model)
        .then(x => {
            this.$router.push('/footwear-calculator/summary/' + this.estimationIdentifier);
        })
    }
}
